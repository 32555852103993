import React from "react"
import { graphql } from "gatsby"
import Parse from "html-react-parser"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from '../../components/image'

// Components
import BlockHours from '../../components/elements/02-blocks/BlockHours'

const Page = ({ data }) => {

   return (
      <Layout>
         <SEO WPPageID={data.wordpressPage.wordpress_id} />
         
         <div className="site page page--contact">
            
            <main id="main" className="container container-xl">
               <div className="page-title">
                  <h1>Nous contacter</h1>
               </div>

               <article>
                  {Parse(data.wordpressPage.content)}
               </article>

               <div className="contact-hours">
                  <BlockHours mPageContact={true} />
               </div>

               <aside>
                  <Image imageID={data.wordpressPage.acf.contact_image} mSize={`medium`} />
               </aside>
            </main>
         </div>
      </Layout>
   )
}

export default Page

export const query = graphql`
   query($id: Int!) {
      wordpressPage(wordpress_id: { eq: $id }) {
         wordpress_id
         title
         content
         acf {
            contact_image
         }
      }
   }
`
